import styled from '../styled';

export const FeatureTable = styled('table')`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    font-weight: 500;
    text-align: left;
    color: #999;
  }

  td,
  th {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }

  tr {
    color: #333;
  }

  thead {
    border-bottom: 1px solid #000;
  }
`;
