import React from 'react';
import MainLayout from '../../layouts/main';
import { Button, Typography } from '@material-ui/core';
import styled from '../../styled';
import { Check, Dash } from '../../components/Check';
import PageHead from '../../components/PageHead';
import { sendCtaClickEvent } from '../../tracking';
import { SEO } from '../../components/SEO';
import { SignupButton } from '../../components/SignupButton';
import { ComparisonTableContent } from '../../components/ComparisonTable';
import { GetStarted } from '../../components/GetStarted';
import { BrunaTestimonial } from '../../components/TestimonialBar';
import { Usps } from '../../components/Usps';
import { FeatureTable } from '../../components/FeatureTable';
import { CALENDAR_LINK } from '../../constants/demo-link';
import { DemoButton } from '../../components/DemoButton';
import { AlertBox } from '../../components/AlertBox';
import { Link } from 'gatsby';

const Hr = styled('hr')`
  border: 1px solid #eee;
  margin-bottom: 48px;
  max-width: 300px;
`;

const Headline = styled(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/alternatives/affluent/"
        title="Looking for Affluent.io alternatives? Try Affilimate"
        description="Looking for the perfect affiliate analytics platform? Here's how Affilimate stacks up against Affluent as an alternative."
      />
      <PageHead style={{ marginBottom: 0 }}>
        <AlertBox
          variant="pending"
          style={{ maxWidth: '500px', margin: '0 auto 24px' }}
        >
          <p>
            <strong>Head's up.</strong> It's come to our attention that Affluent
            is no longer accepting publisher clients.{' '}
            <Link to="/" style={{ borderBottom: '1px solid' }}>
              Try Affilimate instead
            </Link>
          </p>
        </AlertBox>
        <Headline variant="h4" component="h1">
          Looking for a Affluent.io alternative?
        </Headline>
        <Typography
          paragraph
          style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
        >
          Affluent caters to agencies and advertisers. Affilimate is designed
          for publishers from the ground up, with content analytics and revenue
          attribution as our key differentiators.
        </Typography>

        <SignupButton />
        <DemoButton style={{ marginLeft: '12px' }} />
      </PageHead>
      <br />
      <br />
      <Usps />
      <br />
      <br />
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ textAlign: 'center' }}
      >
        Affilimate vs. Affluent
      </Typography>
      <Typography
        paragraph
        variant="body2"
        color="textSecondary"
        style={{
          maxWidth: '600px',
          padding: '24px',
          textAlign: 'center',
          margin: '0 auto'
        }}
      >
        See if Affilimate or Affluent is the better fit for your business.
      </Typography>
      <div style={{ maxWidth: '800px', margin: '96px auto' }}>
        <FeatureTable>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Feature</th>
              <th style={{ width: '20%' }}>Affilimate</th>
              <th style={{ width: '20%' }}>Affluent</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Affiliate Dashboard for Publishers</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Revenue Attribution</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Link Management</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Link Generator</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Content Reporting</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Conversion Heatmaps</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Revision History</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Link Checker (Amazon)</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Link Cloaker</td>
              <td>
                <Dash />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Transaction API</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Agency, advertiser integrations</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>White-label reports</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Branded client portal</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Designed for publishers</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Independently owned</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Self-service signup</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
          </tbody>
        </FeatureTable>
        <Typography
          paragraph
          color="textSecondary"
          style={{
            margin: '48px auto',
            maxWidth: '800px',
            textAlign: 'center'
          }}
        >
          <strong>Our conclusion.</strong> Affluent is a great option for
          agencies running affiliate programs for brands, or advertisers in
          multiple networks. But as a publisher, Affilimate's content and
          attribution-focused features will help you actually grow your revenue
          as opposed to simply tracking it.
        </Typography>
      </div>
      <div style={{ margin: '0 auto 48px', textAlign: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Not sure which is right for your site?
        </Typography>
        <Typography
          paragraph
          style={{ margin: '24px auto', maxWidth: '800px' }}
        ></Typography>
        <Button
          variant="outlined"
          color="primary"
          href={CALENDAR_LINK}
          target="_blank"
          style={{ marginRight: '8px' }}
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Book a demo
        </Button>
        or
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: '8px' }}
          href="mailto:sales@affilimate.com"
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Get in touch
        </Button>
      </div>
      <BrunaTestimonial />
      <ComparisonTableContent />
      <GetStarted />
      <Hr />
      <div style={{ margin: '24px auto 48px', textAlign: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Still deciding?
        </Typography>
        <Typography
          paragraph
          style={{ margin: '24px auto', maxWidth: '800px' }}
        >
          We're happy to help determine the best solution for your unique
          situation as a publisher.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href={CALENDAR_LINK}
          data-savvycal-embed
          target="_blank"
          style={{ height: '36px', marginRight: '8px' }}
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Book a demo
        </Button>
        or
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: '8px' }}
          href="mailto:sales@affilimate.com"
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Get in touch
        </Button>
      </div>
    </div>
  </MainLayout>
);
